<template>
  <div
    class="community-index"
    ref="community"
  >
    <banner-bg :type="7">
      <div class="community-index-base">
        <div class="img">
          <div class="title-body">
            <p class="p1">热门社区，精选作品集</p>
            <p class="p2">学习精彩作品，二次改编发布</p>
          </div>
        </div>
      </div>
    </banner-bg>
    <div class="community-index-top item">
      <div class="community-index-banner sub-item-left">
        <el-carousel
          height="364px"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="(item,key) in bannerList"
            :key="key"
          >
            <a
              :href="item.href"
              target="_blank"
            >
              <el-image
                style="height:364px;width:100%"
                fit="fill"
                :src="item.picUrl"
              ></el-image>
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div
        class="community-index-info sub-item-right"
        v-if="programmerList.length"
      >
        <a
          :href="`/userinfo?uid=${programmerList[0].userId}`"
          target="_blank"
        >
          <div class="first-head-pic">
            <div class="first-head-pic-body">
              <img :src="programmerList[0].avatar" />
            </div>
            <img
              src="@/assets/image/community/head-base.png"
              class="head-base"
            />
            <p class="level-1">1</p>
          </div>
        </a>
        <div class="first-info">
          <p class="name"><a
              :href="`/userinfo?uid=${programmerList[0].userId}`"
              target="_blank"
            >{{ programmerList[0].name }}</a></p>
          <p class="level">{{ programmerList[0].level }}</p>
          <ul>
            <li>
              <i class="iconfont iconzuopinshuliang"></i><span class="num">{{ programmerList[0].product }}</span>
              <p>发布作品数量</p>
            </li>
            <li>
              <i class="iconfont icondianzan979EB1"></i><span class="num">{{ programmerList[0].ups }}</span>
              <p>作品获赞次数</p>
            </li>
            <li>
              <i class="iconfont iconguankancishu"></i><span class="num">{{ programmerList[0].views }}</span>
              <p>作品获浏览量</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="community-index-works-and-programmer item">
      <div class="community-index-works sub-item-left">
        <p class="title">热门作品</p>
        <div class="search-block">
          <el-select
            v-model="searchParams.room"
            placeholder="请选择"
            style="margin-right: 10px;width:200px"
            @change="getDatas(true)"
            clearable
          >
            <el-option
              v-for="item in roomList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="searchParams.sort"
            placeholder="请选择"
            style="margin-right: 10px;width:200px"
            @change="getDatas(true)"
          >
            <el-option
              v-for="item in sortList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="search-child">
            <el-input
              @clear="getDatas(true)"
              v-model="searchParams.keyword"
              :clearable="true"
              placeholder="作品/作者名称"
              style="width: 200px; margin-right: 10px;"
              @change="getDatas(true)"
            />
            <el-button
              type="primary"
              @click="getDatas(true)"
            >搜索</el-button>
          </div>
        </div>
        <div class="works-list">
          <p
            class="no-data"
            v-if="worksList.length==0"
          >暂无数据！</p>
          <!-- <ProgramList :datas="worksList"></ProgramList> -->
          <ProgramTable :datas="worksList"></ProgramTable>
          <div class="table-pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="16"
              layout="total, prev, pager, next,jumper"
              :total="dataTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="community-index-programmer sub-item-right">
        <div class="title">
          <p>编程家排行</p>
          <router-link
            to="rank"
            class="rank-link"
          >排行中心>></router-link>
        </div>
        <div class="programmer-list">
          <ul>
            <li
              v-for="(programmer, index) in bottomProgrammerList"
              :key="programmer.id"
            >
              <div class="head-pic">
                <div class="head-pic-body">
                  <a
                    :href="`/userinfo?uid=${programmer.userId}`"
                    target="_blank"
                  ><img :src="programmer.avatar" /></a>
                </div>
                <p>{{index+2}}</p>
              </div>
              <div class="programmer-info">
                <p class="programmer-name"><a
                    :href="`/userinfo?uid=${programmer.userId}`"
                    target="_blank"
                  >{{programmer.name}}</a></p>
                <p class="level">等级：{{programmer.level}}</p>
              </div>
              <p class="resource">
                <i class="iconfont iconzuopinshuliang"></i><span class="num">{{ programmer.product }}</span>
                <span>
                  <i class="iconfont icondianzan979EB1"></i><span class="num">{{ programmer.ups }}</span>
                </span>
                <span>
                  <i class="iconfont iconguankancishu"></i><span class="num">{{ programmer.views }}</span>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanners } from '@/api/common';

import { getWorks, getCoders, getBanner } from "@/api/community"
import ProgramList from '@/components/ProgramList';
import ProgramTable from '@/components/ProgramTable';
import BannerBg from '@/components/BannerBg.vue';
import { LABS } from '@/utils/page-resource';
export default {
  name: 'Community-index',
  components: {
    ProgramList,
    ProgramTable,
    BannerBg
  },
  data() {
    return {
      bannerList: [],
      worksList: [],
      searchParams: {
        room: parseInt(this.$route.query.lab || 0)||'',
        sort: 1,
        keyword: '',
      },
      roomList: [
        // {
        //   value: 0,
        //   label: '全部实验室',
        // },
        ...LABS.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
      ],
      sortList: [
        {
          value: 1,
          label: '按最受欢迎排序',
        },
        {
          value: 2,
          label: '按最新时间排序',
        }
      ],
      programmerList: [],
      bannerList: [],
      page: 0,
      pageNo: 1,
      dataTotal: 0,
      isEnd: false
    };
  },
  computed: {
    bottomProgrammerList() {
      if (this.programmerList.length) {
        return this.programmerList.splice(1, this.programmerList.length - 1);
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.getDatas();
    this.getCodersDatas();
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'sq',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // getBannerList() {
    //   getBanner().then(res => {
    //     if (res.success && res.result && res.result.records.length) {
    //       this.bannerList = res.result.records.map(item => {
    //           return {
    //             id: item.id,
    //             img: item.topBanner,
    //             link: item.studyLink
    //           }
    //       })
    //     }
    //   })
    // },
    getCodersDatas() {
      getCoders({}).then(res => {
        if (res.success) {
          this.programmerList = res.result.map((item, index) => {
            return {
              userId: item.userId,
              img_pic: item.avatar,
              product: item.workNum,
              name: item.name,
              views: item.viewNum,
              ups: item.starNum,
              avatar: item.avatar,
              level: index <= 3 ? '小小编程家' : index <= 6 ? '高级编程师' : '初级编程师'
            }
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getDatas(false);
    },
    getDatas(isInit) {
      if (isInit) {
        this.worksList = [];
        this.page = 0;
        // this.isEnd = false;
      }
      // if (this.isEnd) {
      //   return;
      // }
      this.page += 1;
      let params = { sort: this.searchParams.sort, pageNo: this.page, pageSize: 16 };
      if (this.searchParams.keyword) {
        params.title = this.searchParams.keyword;
      }
      if (this.searchParams.room) {
        params.fileType = this.searchParams.room;
      }
      getWorks(params).then(res => {
        if (res.success) {
          this.dataTotal = res.result.total;
          this.worksList = [];
          this.worksList.push(...res.result.records.map(item => {
            let tags = [item.fileType_dictText]
            // if (item.tagGrade) tags.push((item.tagGrade.split('/')[1] || item.tagGrade.split('/')[0]).trim())
            tags.push(item.tagDifficulty)
            return Object.assign({}, item, { star: false, collect: false, tags: tags })
          }));
          // if (res.result.records.length < 16) {
          //   this.isEnd = true;
          // }
        }
      })
    },
    scrollHandle() {
      var content = this.$refs.community;
      if (content.scrollTop + content.clientHeight >= content.scrollHeight - 150 && this.worksList.length) {
        this.getDatas(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/community-index.scss';
.community-index-base {
  .img {
    position: relative;
    min-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
}

.banner-block {
  height: 300px;
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 50px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rank-link {
    color: #5f80ff;
    font-size: 12px;
    position: relative;
    top: 8px;
  }
}
</style>
